import React from "react";
import {graphql} from "gatsby";
import images from "../components/images"
import Layout from "../components/Layout";
import RelatedPost from "../components/RelatedPost";
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser'



export default ({data}) => {
    const post = data.blog.edges[0].node

    let yoast_head = post.yoast_head.replace(/http:\/\/cosmoally.net/ig, 'https:\/\/cosmoally.com')
    let temp = yoast_head.match(/<script.+?<\/script>/g)[0] || ''
    let schema = temp !== undefined && temp !== null && temp.length > 70 ? temp.substring(62, temp.length-9) : {}
    let des = post.excerpt.substr(3, post.excerpt.length - 8) || '';


    return (
        <Layout>

            <Helmet>
                <html lang="en" />
                <title>{post.title}</title>

                {post.yoast_head.match(/name="description"/g) === null ? <meta name="description" content={des}/> : null}

                { ReactHtmlParser(yoast_head) }

                <script type="application/ld+json">
                    {schema}
                </script>

            </Helmet>


            <div>
                <img src={images.blogBanner}
                     className="Cosmetic Registration in India: What is the difference between Legal and Actual Manufacturer?"/>
            </div>
            <aside className="container px-0 mx-auto">
                <div className="row mob">
                    <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
                        <div className="layout " style={{marginTop:'30px'}}>
                            <article
                                className="post-212 post type-post status-publish format-standard has-post-thumbnail hentry category-cosmetic tag-cosmetic-registration tag-cosmetic-registration-certificate tag-cosmetic-registration-guide">
                                <div className="testimonial_box blog-post">
                                    <div className="info">
                                        Written by {post.author.name} <span
                                        className="point-red">&nbsp;&nbsp;•&nbsp;</span> {post.date}
                                        {post.categories.map(node => (
                                          <>
                                        <span
                                        className="point-red">&nbsp;&nbsp;•&nbsp;</span>
                                        <span className="cat"> 
                                           <a href={"/category/"+node.name.toLowerCase()}
                                           rel="category tag">
                                             {node.name}</a></span></> ))}
                                       </div>
                                        <h1  dangerouslySetInnerHTML={{__html:post.title}} />
                                    <div className="post-desc1">
                                        <div dangerouslySetInnerHTML={{__html: post.content.replace(/http:\/\/cosmoally.net/ig, 'https:\/\/cosmoally.com')}}/>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                        <div className="sidebar-custom">
                            <h3>Related Post</h3>
                            {data.allBlogs.edges.slice(0, 4).map((data, index) => (<RelatedPost postData={data}/>))}

                                <section id="recent-posts-2" className="widget widget_recent_entries"><h3
                                className="widget-title">Recent Posts</h3>
                                <ul>  {data.allBlogs.edges.slice(0, 4).map((data, index) => (
                                <li> <a href={"https://cosmoally.com/"+data.node.slug+"/"}>{data.node.title}</a></li>
                                ))}</ul>
                            </section>
                            <section id="recent-comments-2" className="widget widget_recent_comments"><h3
                                className="widget-title">Recent Comments</h3>
                                <ul id="recentcomments"> </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </aside>
        </Layout>
    )
}

export const query = graphql`
  query myQuery($slug: String!) {
    blog:allWordpressPost(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            title
            content
            excerpt
            slug
            author {
              name
            }
            categories {
              name
              link
            }
            date(formatString: "DD-MMM-YYYY")
            yoast_head
          }
        }
      }
      allBlogs:allWordpressPost  {
        edges {
          node {
            title
            content
            slug
            excerpt
            date(formatString: "DD-MMM-YYYY")
            categories {
              name
              link
            }
            featured_media {
                localFile {
                  childImageSharp {
                    resolutions(width: 300, height: 300) {
                      src
                      height
                      width
                    }
                }
              }
            }
          }
        }
      }
  }`
