import React, { Component } from "react"

const RelatedPost = data => {

    return (
      
        <div className="testimonial_box blog-post  mypost  box-shadow">
            <a
                href={"https://cosmoally.com/"+data.postData.node.slug+"/"}
                title={data.postData.node.title}>
            <img src={data.postData.node.featured_media.localFile.childImageSharp.resolutions.src} className="attachment-full size-full wp-post-image"
                 alt="Cosmetic Registration in India: Payment Process of Government Fee"/>
        </a>
        <div className="post-desc">
            <h2><a
                href={"https://cosmoally.com/"+data.postData.node.slug+"/"}
                title={data.postData.node.title}>
                {data.postData.node.title}</a></h2>
            <div className="info">
            {data.postData.node.date} 
                {data.postData.node.categories.map((data, index) => (
                <>
                <span class="point-red">&nbsp;&nbsp;•&nbsp;</span>
                <span className="cat">
                <a href={data.link.replace(/http:\/\/cosmoally.net/ig, 'https:\/\/cosmoally.com')}>{data.name}</a></span>
                </>))}
               
            </div>
            
            <div dangerouslySetInnerHTML={{__html: data.postData.node.excerpt}}/>

        </div>
    </div>

      
    )
}
export default RelatedPost
